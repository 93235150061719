import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ModuleOrganization, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Screens from "constants/screens";
import Strings from "constants/strings";

import { RootState } from "store";
import { Mode } from "constants/enum";
import { DashboardLayout } from "layout";
import { IModel } from "commons/interfaces";
import { setListPathName } from "store/slice/titleRoute.slice";
import { fetchUserInfo, storeListOrganization } from "store/slice/userInfo.slice";


interface IRequestGetPage {
    orderby?: string;
    pageSize?: number;
    pageNumber?: number;
    totalCount?: number;
    searchText?: string;
    status?: string[];
    organizationCode?: string;
}

const OrganizationScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { getResourcePermissions } = useCommonComponentContext();

    const id = searchParams.get("id");
    const modeList = (Screens.ORGANIZATION_LIST.includes(window.location.pathname));
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);
    const resourcePermissions = getResourcePermissions(Constants.ResourceCode.ORGANIZATION);

    const itemPathName = listPathName.find(el => el.pathName === Screens.ORGANIZATION_LIST);

    const [model, setModel] = useState<IModel>({});
    const [requestData] = useState<IRequestGetPage>({
        status: searchParams.getAll("status"),
        searchText: searchParams.get("searchText"),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        organizationCode: searchParams.get("organizationCode") || undefined,
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
        totalCount: itemPathName?.totalCount,
    } as IRequestGetPage);

    useEffect(() => {
        if (Helpers.isNullOrEmpty(id)) {
            handleChangeMode(Mode.Create);
        } else {
            let mode: number = resourcePermissions.canUpdate ? (pramsMode || Mode.Update) : Mode.View;
            handleChangeMode(mode);
        }
    }, [id, pramsMode]);


    const handleGoBack = () => {
        const itemP = listPathName.find((el) => el.pathName === Screens.ORGANIZATION_LIST);
        navigate(Screens.ORGANIZATION_LIST + (itemP ? itemP.query : ""));
    };

    const handleChangeMode = (value: Mode) => {
        let title: string = "";
        let route = [{ title: Strings.ORGANIZATION.TITLE_MENU, route: Screens.ORGANIZATION_LIST }];

        if (value === Mode.Create) {
            title = Strings.ORGANIZATION.TITLE_CREATE_VIEW;
            route = [
                { title: Strings.ORGANIZATION.TITLE_MENU, route: Screens.ORGANIZATION_LIST },
                { title: Strings.Common.CREATE_NEW, route: "" },
            ];
        }
        if (value === Mode.Update) {
            title = Strings.ORGANIZATION.TITLE_UPDATE_VIEW;
            route = [
                { title: Strings.ORGANIZATION.TITLE_MENU, route: Screens.ORGANIZATION_LIST },
                { title: Strings.Common.UPDATE, route: "", },
            ];
        }
        if (value === Mode.View) {
            title = Strings.ORGANIZATION.TITLE_DETAIL_VIEW;
            route = [
                { title: Strings.ORGANIZATION.TITLE_MENU, route: Screens.ORGANIZATION_LIST },
                { title: Strings.Common.DETAIL, route: "", },
            ];
        }

        setModel({
            ...model,
            mode: value,
            title: title,
            route: route,
        });

        if (!Helpers.isNullOrEmpty(id)) {
            navigate(Screens.ORGANIZATION_EDIT + `?id=${id}&mode=${value}`, { replace: true });
        }
    }

    return (
        <DashboardLayout
            isPermission={resourcePermissions.canRead}
            title={modeList ? Strings.ORGANIZATION.TITLE_LIST_VIEW : model.title}
            route={modeList ? [{ title: Strings.ORGANIZATION.TITLE_MENU, route: "" }] : model.route}
        >
            <ModuleOrganization
                hidenActionCreate={!resourcePermissions.canCreate}
                hidenActionDelete={!resourcePermissions.canDelete}
                hidenActionUpdate={!resourcePermissions.canUpdate}

                keyModuleContainer={{
                    list: { pathName: Screens.ORGANIZATION_LIST },
                    detail: { pathName: Screens.ORGANIZATION_EDIT },
                    create: { pathName: Screens.ORGANIZATION_CREATE },
                }}

                requestGetPaged={requestData}
                onGetPaged={async ({ query, totalCount }) => {
                    dispatch(setListPathName({ pathname: Screens.ORGANIZATION_LIST, query, totalCount: totalCount }));
                    navigate(Screens.ORGANIZATION_LIST + query, { replace: true });
                }}
                onUpdateListOrganization={async ({ data, isFetchUserInfo }) => {
                    if (isFetchUserInfo) {
                        Helpers.setItemInLocalStorage(Constants.StorageKeys.ORGANIZATION_ID, "");
                        dispatch(fetchUserInfo());
                    }
                    dispatch(storeListOrganization(data));
                }}
                onNavigate={async (data) => {
                    if (data.id) {
                        navigate(Screens.ORGANIZATION_EDIT + `?id=${data.id}&mode=${data.mode}`);
                    } else {
                        navigate(Screens.ORGANIZATION_CREATE);
                    }
                }}

                idDetail={id}
                mode={model.mode}
                onGoBack={handleGoBack}
                onChangeMode={(value) => { handleChangeMode(value) }}
            />
        </DashboardLayout>
    );
};


export default OrganizationScreen;
